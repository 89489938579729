.carousel-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px 0;
  padding: 0;
  list-style: none;
  z-index: 1;

  & > span {
    width: 32px;
    height: 3px;
    background-color: #9e9e9e;
    opacity: 1;
    margin: 0 5px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      width: 48px;
      height: 5px;
      background-color: #fff;
    }
  }
}
