.toast-container {
  position: fixed;
  //in the center of the top of the screen
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 10000;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
  transform: translateY(-100px);
  opacity: 0;
  pointer-events: none;
  &.show {
    transform: translateY(0);
    transform: translateX(-50%);
    opacity: 1;
    pointer-events: all;
  }
  .toast {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 1em;
    color: #e3ebf0;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);

    &.success {
      background-color: #4caf50;
      color: #fff;
    }
    &.error {
      background-color: #f44336;
      color: #fff;
    }
    &.warning {
      background-color: #ff9800;
      color: #fff;
    }
    &.info {
      background-color: #2196f3;
      color: #fff;
    }
    .toast-message {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
