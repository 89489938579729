.show-forgot-password-response-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
  gap: 1.5rem;
  .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #0d3e9b);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-zp9dbg 1s infinite linear;
  }

  @keyframes spinner-zp9dbg {
    to {
      transform: rotate(1turn);
    }
  }

  .show-forgot-password-response-heading {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .show-forgot-password-response-caption {
    color: #263238;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .show-forgot-password-response-button {
    border-radius: 10px;
    background: #0d3e9b;
    color: #fff;
    width: 90%;
    padding: 0.5rem 1rem;
    border: none;
    font-weight: 700;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
  }
  .show-forgot-password-response-button:hover {
    opacity: 0.7;
    transform: scale(1.1);
    background: #d3e9f4;
    color: #000;
  }
}
