//global css styles for div and base layout

.App {
  text-align: center;
  height: 100vh;
  width: 100%;

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .dots {
      width: 17.3px;
      height: 17.3px;
      border-radius: 50%;
      clip-path: inset(-36px -144px);
      color: #0d3e9b;
      box-shadow: 27.4px -57.6px, 54.7px -57.6px, 82.1px -57.6px;
      transform: translateX(-54.7px);
      animation: dots-y3c9kslg 1s infinite;
    }

    @keyframes dots-y3c9kslg {
      16.67% {
        box-shadow: 27.4px 0px, 54.7px -57.6px, 82.1px -57.6px;
      }

      33.33% {
        box-shadow: 27.4px 0px, 54.7px 0px, 82.1px -57.6px;
      }

      45%,
      55% {
        box-shadow: 27.4px 0px, 54.7px 0px, 82.1px 0px;
      }

      66.67% {
        box-shadow: 27.4px 57.6px, 54.7px 0px, 82.1px 0px;
      }

      83.33% {
        box-shadow: 27.4px 57.6px, 54.7px 57.6px, 82.1px 0px;
      }

      100% {
        box-shadow: 27.4px 57.6px, 54.7px 57.6px, 82.1px 57.6px;
      }
    }
  }
}

> div {
  margin: 0 auto;
  padding: 0px;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
