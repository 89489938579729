.login-page-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100vh;
  opacity: 1;
  flex-wrap: wrap;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    &.show {
      pointer-events: all;
    }
    .loader {
      width: 5.8px;
      height: 5.8px;
      border-radius: 50%;
      color: #2196f3;
      box-shadow: 27.4px 0 0 10.1px, 54.8px 0 0 4.3px, 82.19999999999999px 0 0 0;
      transform: translateX(-54.8px);
      animation: dots-ijr34dlg 0.6s infinite alternate linear;
    }

    @keyframes dots-ijr34dlg {
      50% {
        box-shadow: 27.4px 0 0 4.3px, 54.8px 0 0 10.1px,
          82.19999999999999px 0 0 4.3px;
      }

      100% {
        box-shadow: 27.4px 0 0 0, 54.8px 0 0 4.3px,
          82.19999999999999px 0 0 10.1px;
      }
    }
  }
}

.initial-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edd8d9;
  background: -webkit-linear-gradient(
    0deg,
    #edd8d9 0%,
    #ffffff 50%,
    #edd8d9 100%
  );
  background: linear-gradient(0deg, #edd8d9 0%, #ffffff 50%, #edd8d9 100%);
  z-index: -1;
  &.show {
    pointer-events: all;
  }
  .progress {
    width: 300.2px;
    height: 30.7px;
    border-radius: 30px;
    color: #edd8d9;
    border: 3px solid;
    position: relative;
  }

  .progress::before {
    content: "";
    position: absolute;
    margin: 3.5px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: progress-pf82op 1.9s infinite;
  }

  @keyframes progress-pf82op {
    100% {
      inset: 0;
    }
  }
}

.login-page-container-mobile {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  background-image: url("../../images/mobileBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
