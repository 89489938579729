.text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: #ffffff;
  padding: 20px 20px;
  background: rgba(0, 0, 0, 0.29);
  background: -webkit-linear-gradient(
    179deg,
    rgba(0, 0, 0, 0.29) 0%,
    rgba(0, 0, 0, 0.23) 100%
  );
  background: linear-gradient(
    179deg,
    rgba(0, 0, 0, 0.29) 0%,
    rgba(0, 0, 0, 0.23) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  .head {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.0025em;
    text-align: left;
    margin-bottom: 20px;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.0025em;
    text-align: left;
    margin-bottom: 20px;
  }
}
