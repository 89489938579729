.login-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 400px;
  min-height: 400px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.42);
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(18px);
  .login-component-back-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .button-style {
      width: 10%;
      border: none;
      background: none;
      color: #000;
      opacity: 0.7;
      font-size: 16px;
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: 0.2px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
  .login-component-logo {
    width: 90%;
    margin: 2rem 0 2rem 0;
    //subtle animation
    animation: fadeIn 1s cubic-bezier(0.25, 0.8, 0.25, 1);
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-100px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .login-component-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 1rem;
    .login-component-form-input {
      width: 100%;
      border-radius: 10px;
      background: #e6e6e6;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &:focus {
        outline: none;
        border: 1px solid #000;
      }
      .input-field {
        width: 100%;
        border: none;
        background: #e6e6e6;
        font-size: 1rem;

        &::placeholder {
          color: #aeadad;
          font-size: 14px;
          font-weight: 400;
        }
        &:focus {
          outline: none;
        }
      }
      .password-toggle-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        opacity: 0.8;
        &:focus {
          outline: none;
        }
        &:hover {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
    .login-component-form-invalid-login {
      color: #ff0000;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin: 0.5rem 0 0.5rem 0;
      max-width: 380px;
    }
    .login-component-form-button {
      width: 100%;
      padding: 0.5rem;
      border: none;
      border-radius: 10px;
      background: #0d3e9b;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      // add transition delay
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        background: #aeadad;
        opacity: 0.8;
      }
      .button-style {
        color: #fff;
        width: 100%;
        border: none;
        background: none;
        font-size: 16px;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.2px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:focus {
          outline: none;
        }
        &:hover {
          opacity: 1;
          color: #0d3e9b;
        }
      }
    }
    .login-component-form-forget-password {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      .forget-password {
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
        margin: 24px 0 24px 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:hover {
          text-decoration: underline;
          transform: scale(1.1);
        }
      }
    }
  }
  .horizontal-separator {
    width: 80%;
    height: 1px;
    background: #dfdfdf;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  > .mobile {
    margin-bottom: 0px;
  }
}
