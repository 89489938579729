.error-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: #d7d7d7;
  background: -webkit-linear-gradient(0deg, #d7d7d7 0%, #eeeeee 100%);
  background: linear-gradient(0deg, #d7d7d7 0%, #eeeeee 100%);
  gap: 1rem;

  .image {
    width: 500px;
    height: 372.61px;
    object-fit: cover;
    z-index: 1;
    background-image: url("../../images/errorPageImage.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .error-message {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    max-width: 65%;
    margin: 1rem 0;
    line-height: 1.25;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .button {
    width: 252px;
    height: 48px;
    border-radius: 10px;
    background: #0d3e9b;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    border: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      background-color: #e3ebf0;
      color: #0d3e9b;
    }
  }
}
