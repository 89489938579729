.sso-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  .sso-button {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      background-color: #e3ebf0;
    }
    .sso-button-logo {
      width: 25px;
      height: 25px;
    }
  }
}
